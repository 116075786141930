import React from 'react';


const footer = () => {
    return (  
        <div className="footer">
        <div className = 'bottom-footer container text-light pt-3 pb-3'>
        <div className='row'>

        <div style={{textAlign: 'center'}} className = 'col-12 col-sm-12 col-md-12 col-lg-12'>
        <p>
        <small>Copyright © 2023 ValidAlpha AB - All Rights Reserved.</small>
        </p>
        </div>
        </div>
        </div>
        </div>
      


    );
};

export default footer;