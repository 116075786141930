import {React } from 'react';

import Layout from './components/Layout/Layout';

// TEST TO SEE IF NEW ACURE URL IS GENERATED ON DEPLOY

function App() {
  return <Layout />;
}

export default App;
