import React from "react";
import ReactDOM from "react-dom/client";
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/main.scss';
// import { CookiesProvider } from "react-cookie";
import App from './App';



import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
      {/* <CookiesProvider> */}
    <App />
    {/* </CookiesProvider> */}
  </React.StrictMode>
);

reportWebVitals();