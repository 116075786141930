import { React } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
// import CookieConsent from "react-cookie-consent";

import Header from '../UI/Header/Header';
import Footer from '../UI/Footer/Footer';
import About from '../pages/About/About';
// import Team from '../pages/Team/Team';
// import Research from '../pages/Research/Research';
// import Contact from '../pages/Contact/Contact';
// import Proinvestor from '../pages/Proinvestor/Proinvestor';
// import ValidOne from '../pages/ValidOne/ValidOne';
// import Login from '../pages/ValidOne/Login';
// import FundNews from '../pages/FundNews/FundNews';
// import Prefund from '../pages/Prefund/Prefund';
// import AboutCookies from '../pages/Policies/AboutCookies';
// import PrivacyNotice from '../pages/Policies/PrivacyNotice';
// import TermsOfUse from '../pages/Policies/TermsOfUse';
import ScrollToTop from './ScrollToTop';

const Layout = () => {
  return (
    <Router>
    <ScrollToTop />

    <div className="f-container fixed-hf">
      <Header />
      {/* <CookieConsent
          disableStyles={true}
          buttonClasses="btn btnDefault cookieBtn"
          containerClasses="cookieContainer"
          contentClasses="cookieContent"
          location="bottom"
          buttonText="I Agree"
          cookieName="agree-cookie"
          expires={365}
        >
          <span className="cookieHeader">Accepting Cookies</span> <br />
          <span className="cookieP">
            By clicking Continue, you agree to our use of cookies. If you would
            like more information, read our{" "}
            <Link className="cookiePP" to="/privacy">
              Privacy Notice 
            </Link>
            &nbsp;and {" "}
             <Link className="cookiePP" to="/cookies">
              About Cookies
            </Link>
            .
          </span>
        </CookieConsent> */}
      <Routes>
      <Route exact path='/' element={<About/>}/>
      <Route exact path='/about' element={<About/>}/>

      </Routes>
      <Footer />
      </div>

    </Router>
  );
};

export default Layout;
