import React from 'react';
import logoImage from '../../../assets/img/ValidAlpha_fullname_black.png';


const About = () => {
    return ( 
      <div className = 'container pt-5 pb-5' >
                <div className='aboutImage'>
              <img src={logoImage} alt='ValidAlpha'/>
            </div>
        <div className='section-content'>
      <div className = 'section-header pt-5 pb-5 text-center' >
        </div> 

        
        <div className='row'>
          <div className='col-md-24 col-lg-12'>
            {/* <div style={{textAlign: 'center'}} className='about-description' > */}
            <div style={{textAlign: 'center'}} className='about-description' >
            {/* <p>ValidAlpha is a team of dedicated algorithm builders and traders based in Sweden. We research, develop, and execute</p>
            <p>advanced market-neutral arbitrage strategies for the digital assets markets, utilizing state-of-the-art AI-technology.</p>  */}
            <br></br>
            <br></br>
            <p>Contact : info (at) validalpha.ai</p>
            </div>
          </div>
        </div>
      </div>

        </div> 
    );
};

export default About;