import React from 'react';


const header = () => {
    return ( <div className = 'header'>
        


        </div>
    );
};

export default header;